<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <v-list class="mt-2" three-line v-if="lavorazioni.length">
              <template v-for="(lavorazione, index) in lavorazioni">
                <v-list-tile
                  @click="openLavorazioneDialog(lavorazione)"
                  :key="'tile-'+lavorazione._id">
                  <v-list-tile-content>
                    <v-list-tile-title class="text-capitalize" v-text="lavorazione.codice_ingresso"></v-list-tile-title>
                    <v-list-tile-sub-title>{{ lavorazione.descrizione }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-list-tile-action-text>
                      <v-chip>Componenti: {{ lavorazione.componenti.length }}</v-chip>
                    </v-list-tile-action-text>
                    <v-list-tile-action-text>
                      <v-tooltip left v-if="!lavorazione.inviata && lavorazione.componenti.length">
                        <v-icon
                          class="mr-2"
                          slot="activator"
                          @click.stop="confirmGestione(lavorazione)"
                          color="primary">
                          keyboard_tab
                        </v-icon>
                        <span>Invia alla gestione</span>
                      </v-tooltip>
                    </v-list-tile-action-text>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider :key="lavorazione._id" v-if="index < lavorazioni.length - 1"></v-divider>
              </template>
            </v-list>
            <v-alert
              outline
              color="info"
              value="true"
              v-if="!lavorazioni.length">
              Nessuna lavorazione trovata
            </v-alert>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-speed-dial
      class="fix-speed-dial"
      v-model="fab"
      bottom
      right
      fixed
      direction="top"
      v-if="ingresso.identificativo !== 'CONPLASTPRODUZIONE'"
      transition="slide-y-reverse-transition">
      <v-btn
        color="pink darken-1"
        dark fab right bottom
        slot="activator"
        v-model="fab">
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="green"
        @click="openLavorazioneDialog(null, 1)">
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
        @click="openLavorazioneDialog(null, 0)">
        <v-icon>build</v-icon>
      </v-btn>
    </v-speed-dial>
    <!-- modale lavorazione -->
    <v-dialog
      v-model="showLavorazione"
      transition="dialog-bottom-transition"
      :overlay="false"
      v-show="showLavorazione">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title v-if="lavorazione._id">Modifica lavorazione</v-toolbar-title>
          <v-toolbar-title v-else>Aggiungi lavorazione</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showLavorazione=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formLavorazione" v-model="validFormLavorazione" lazy-validation>
            <v-container fluid>
              <v-layout v-if="ingresso.identificativo !== 'CONPLASTPRODUZIONE'" row wrap>
                <v-flex xs12 lg3 v-if="!lavorazione.libera">
                  <v-autocomplete
                    v-model="lavorazione.ordine"
                    :items="ordini"
                    :search-input.sync="searchOrdine"
                    :disabled="!!lavorazione.inviata"
                    hide-selected
                    hide-no-data
                    item-text="codice_ingresso"
                    item-value="_id"
                    label="Codice finito"
                    placeholder="Cerca un codice finito"
                    prepend-icon="search"
                    clearable
                    return-object
                    @change="onOrdineChange">
                    <template slot="item" slot-scope="{ item }">
                      <v-list-tile-content>
                        <v-list-tile-title>
                           {{item.codice_ingresso}} - {{item.opera}} - {{item.prodotto}}
                           <span v-if="item._id > 0">(P)</span>
                           <span v-else>(A)</span>
                        </v-list-tile-title>
                        <v-list-tile-sub-title>Clicca per aggiungere il codice</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 lg3 v-else>
                  <v-text-field
                    label="Codice finito*"
                    v-model="lavorazione.codice_ingresso"
                    :disabled="!!lavorazione.inviata"
                    :rules="[$rules.required, $rules.maxlength(20)]"
                  />
                </v-flex>
                <v-flex xs12 lg3>
                  <v-text-field
                    label="Descrizione*"
                    v-model="lavorazione.descrizione"
                    :disabled="!!lavorazione.inviata"
                    :rules="[$rules.required, $rules.maxlength(200)]"
                  />
                </v-flex>
                <v-flex xs12 lg3>
                  <v-text-field
                    label="Codice opera"
                    v-model="lavorazione.codice_opera"
                    :disabled="!!lavorazione.inviata"
                    :rules="[$rules.maxlength(16)]"
                  />
                </v-flex>
                <v-flex xs12 lg3>
                  <v-text-field
                    label="Opera"
                    v-model="lavorazione.opera"
                    :disabled="!!lavorazione.inviata"
                    :rules="[$rules.maxlength(200)]"
                  />
                </v-flex>
              </v-layout>
              <v-layout v-if="lavorazione.componenti && lavorazione.componenti.length">
                <v-flex xs12>
                  <v-divider />
                  <v-subheader class="mt-4 pb-2 pa-0"><h3>Componenti</h3></v-subheader>
                </v-flex>
              </v-layout>
              <template v-for="(componente, index) in filteredComponenti(lavorazione.componenti)">
                <v-layout row wrap :key="index">
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Codice stock*"
                      v-model="componente.codice"
                      :disabled="!!lavorazione.inviata || ingresso.identificativo === 'CONPLASTPRODUZIONE'"
                      :rules="[$rules.required, $rules.maxlength(20)]"
                    />
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field
                      label="Descrizione componente*"
                      v-model="componente.descrizione"
                      :disabled="!!lavorazione.inviata || ingresso.identificativo === 'CONPLASTPRODUZIONE'"
                      :rules="[$rules.required, $rules.maxlength(100)]"
                    />
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-select
                      height="32px"
                      :items="tipiLavorazioni"
                      item-text="descrizione"
                      item-value="_id"
                      label="Tipo lavorazione*"
                      v-model="componente.tipo_lavorazione_id"
                      :disabled="!!lavorazione.inviata || ingresso.identificativo === 'CONPLASTPRODUZIONE'"
                      :rules="[$rules.requiredOrZero]"
                      class="selectTruncate"
                    />
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-text-field
                      label="Note"
                      v-model="componente.note"
                      :rules="[$rules.maxlength(4000)]"
                    />
                  </v-flex>
                  <v-flex xs12 sm1>
                    <v-text-field
                      label="Qta dichiarata*"
                      v-model.number="componente.qta_dichiarata"
                      :disabled="!!lavorazione.inviata || ingresso.identificativo === 'CONPLASTPRODUZIONE'"
                      :rules="[$rules.required, $rules.numeric, $rules.maxlength(7)]"
                    />
                  </v-flex>
                  <v-flex xs12 sm1>
                    <v-icon
                      v-if="ingresso.identificativo !== 'CONPLASTPRODUZIONE'"
                      @click.stop="deleteComponente(componente)"
                      class="mt-4"
                      color="error">
                      delete
                    </v-icon>
                  </v-flex>
                </v-layout>
              </template>
              <v-btn
                color="pink darken-1 ml-0"
                dark small fab
                @click.stop="addComponente()"
                v-if="!lavorazione.inviata">
                <v-icon color="white">add</v-icon>
              </v-btn>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0 mt-4"
                    color="primary"
                    :disabled="!validFormLavorazione"
                    @click.native="saveLavorazione">
                    Salva
                  </v-btn>
                  <v-btn
                    class="ma-0 mt-3"
                    color="error"
                    v-if="lavorazione._id && !lavorazione.inviata && ingresso.identificativo !== 'CONPLASTPRODUZIONE'"
                    @click.stop="deleteLavorazione">
                    Elimina lavorazione
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-confirm ref="confirm" />
    <!-- conferma invia lavorazione -->
    <v-dialog v-model="confirmGes" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>Stai inviando questa lavorazione alla gestione.<br /> Da questo momento in poi non potrai più modificarla.</v-card-text>
        <v-card-actions>
          <v-btn @click.native="confirmGes = false">Annulla</v-btn>
          <v-btn color="primary" @click.native="inviaLavorazione">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import _clone from 'lodash/clone'
import _debounce from 'lodash/debounce'
import modalConfirm from '@/components/common/ModalConfirm'

export default {
  components: {
    'modal-confirm': modalConfirm
  },
  props: {
    ingresso: Object
  },
  data: () => ({
    validFormLavorazione: false,
    showLavorazione: false,
    validFormComp: false,
    showComp: false,
    lavorazione: {},
    lavorazioni: [],
    searchOrdine: null,
    confirmGes: false,
    ordine: null,
    ordini: [],
    fab: false,
    tipiLavorazioni: null
  }),
  methods: {
    openLavorazioneDialog (lavorazione, libera) {
      if (lavorazione) {
        this.lavorazione = _clone(lavorazione)
        const ordine = {
          _id: -2,
          codice_ingresso: lavorazione.codice_ingresso
        }
        this.ordini = [ordine]
        this.lavorazione.ordine = ordine
      } else {
        /* const ordine = {
          _id: -2,
          codice_ingresso: ''
        } */
        this.lavorazione = { componenti: [], libera: libera }
        // this.lavorazione.ordine = ordine
      }
      this.showLavorazione = true
    },
    searchOrdini (val) {
      if (!val || (this.lavorazione.ordine && this.lavorazione.ordine._id === -2)) return

      this.$plsqlMethod('ingresso', 'search', { text: val, ingresso: this.$route.params.id })
        .then(response => {
          this.ordini = response.data
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura ordini' })
        })
    },
    onOrdineChange (ordine) {
      if (ordine && (!this.lavorazione.ordine || (this.lavorazione.ordine && this.lavorazione.ordine._id !== -2))) {
        if (this.ordini.length === 1 && this.ordini[0]._id === -1) {
          this.lavorazione = { codice_ingresso: this.ordini[0].codice_ingresso, ordine: this.ordini[0].codice_ingresso, componenti: [] }
          this.$refs.formLavorazione.reset()
        } else {
          this.lavorazione.codice_ingresso = ordine.codice_ingresso
          this.lavorazione.componenti = ordine.componenti || []
          this.lavorazione.descrizione = ordine.prodotto
          this.lavorazione.opera = ordine.opera
          this.lavorazione.codice_opera = ordine.codice_opera
        }
      }
    },
    addComponente () {
      this.lavorazione.componenti.push({
        codice: null,
        descrizione: null,
        lavorazione: null,
        note: null,
        qta_dichiarata: null
      })
    },
    deleteComponente (componente) {
      this.$set(componente, 'deleted', 1)
    },
    filteredComponenti (componenti) {
      if (componenti) {
        return componenti.filter((c) => !c.deleted)
      }
    },
    confirmGestione (lav) {
      if (lav.inviata) return
      if (!lav.componenti.length) {
        $EventBus.$emit('message', { type: 'error', text: 'Non puoi inviare alla gestione una lavorazione senza componenti' })
        return
      }
      if (lav.componenti.find(function (c) { return !c.qta_effettiva })) {
        $EventBus.$emit('message', { type: 'error', text: 'Inserisci prima le quantità effettive di ciascun componente' })
        return
      }
      this.lavorazione = lav
      this.confirmGes = true
    },
    inviaLavorazione () {
      if (!this.lavorazione.componenti.length) {
        $EventBus.$emit('message', { type: 'error', text: 'Non puoi inviare alla gestione una lavorazione senza componenti' })
        return
      }
      if (this.lavorazione.componenti.find(function (c) { return !c.qta_effettiva })) {
        $EventBus.$emit('message', { type: 'error', text: 'Inserisci prima le quantità effettive di ciascun componente' })
        return
      }
      this.$plsqlMethod('ingresso', 'invia_lavorazione', { lavorazione: this.lavorazione._id })
        .then((response) => {
          $EventBus.$emit('message', { type: 'success', text: 'Lavorazione inviata alla gestione' })
          this.confirmGes = false
          this.getLavorazioni()
        })
        .catch((err) => {
          console.log(err)
          this.confirmGes = false
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile inviare la lavorazione alla gestione' })
        })
    },
    saveLavorazione () {
      if (this.$refs.formLavorazione.validate()) {
        if (this.lavorazione.componenti && this.lavorazione.componenti.filter((c) => !c.deleted).length > 0) {
          if (!this.lavorazione.libera) {
            this.lavorazione.codice_ingresso = this.lavorazione.ordine.codice_ingresso
            delete this.lavorazione.ordine
          }
          this.lavorazione.ingresso_id = this.$route.params.id
          this.$plsqlMethod('ingresso', 'save_lavorazione', this.lavorazione)
            .then((response) => {
              $EventBus.$emit('message', { type: 'success', text: 'Lavorazione salvata' })
              this.showLavorazione = false
              this.getLavorazioni()
              this.$refs.formLavorazione.reset()
            })
            .catch((err) => {
              console.log(err)
              $EventBus.$emit('message', { type: 'error', text: 'Impossibile salvare la lavorazione' })
            })
        } else {
          $EventBus.$emit('message', { type: 'error', text: 'Non puoi aggiungera una lavorazione senza componenti' })
        }
      }
    },
    deleteLavorazione () {
      this.$refs.confirm.show({
        modalTitle: 'Conferma eliminazione',
        modalText: 'Stai per cancellare la lavorazione <strong>' + this.lavorazione.codice_ingresso + '</strong>.<br/><strong>Attenzione</strong>: verranno cancellati anche i Componenti a essa associati.',
        modalButtonOk: 'Elimina',
        modalFunctionOk: (lav) => {
          this.$plsqlMethod('ingresso', 'del_lavorazione', { id: this.lavorazione._id })
            .then((response) => {
              $EventBus.$emit('message', { type: 'success', text: 'Lavorazione eliminata' })
              this.confirm = false
              this.showLavorazione = false
              this.getLavorazioni()
              this.$refs.formLavorazione.reset()
            })
            .catch((err) => {
              console.log(err)
              $EventBus.$emit('message', { type: 'error', text: 'Impossibile eliminare la lavorazione' })
            })
        },
        modalObj: this.lavorazione
      })
      this.confirm = true
    },
    getTipiLavorazioni () {
      this.$plsqlMethod('ingresso', 'get_tipilav', { ingresso: this.$route.params.id })
        .then((response) => {
          this.tipiLavorazioni = response.data
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile recuperare i tipi lavorazione' })
        })
    },
    getLavorazioni () {
      this.$plsqlMethod('ingresso', 'get_lavorazioni', { ingresso: this.$route.params.id })
        .then((response) => {
          this.lavorazioni = response.data
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile recuperare le lavorazioni' })
        })
    }
  },
  watch: {
    searchOrdine (val, old) {
      this.debouncedcarcaOrdine(val)
    }
  },
  mounted () {
    this.getTipiLavorazioni()
    this.getLavorazioni()
    this.debouncedcarcaOrdine = _debounce(this.searchOrdini, 3000)
  }
}
</script>

<style>
@media screen and (max-width:1876px) {
    .selectTruncate .v-select__selections {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .selectTruncate .v-select__selections:after {
        content: "...";
    }
    .selectTruncate .v-select__selection {
      overflow: hidden;
      max-width: 80%;
    }
}
.fix-speed-dial.v-speed-dial--direction-top .v-speed-dial__list{
  left: -1em!important;
  bottom: 150%!important;
}
</style>
